import React, { useEffect, useState } from "react";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Typography,
} from "@material-ui/core";
import _ from "lodash";

import DialogCommonDefault from "../../../../components/dialogs/DialogCommonDefault";
import SwitchCommon from "../../../../components/switches/SwitchCommon";
import { DeliveryTpe } from "../../../../utils/enum";
import { DELIVERY_TYPE_DINEIN } from "../../../../utils/consts";
import TextfieldDefaultNew from "../../../../components/textField/TextfieldDefaultNew";
import ButtonCommon from "../../../../components/buttons/ButtonCommon";
import AvailabilitySelection from "../../../../components/time/AvailabilitySelection";
import {
  isEqualArrayObject,
  isEqualArrays,
} from "../../../../utils/checkArrayEqual";
import { useForm } from "react-hook-form";
import ValidationMessage from "../../../../components/validation/ValidationMessage";

interface FormData {
  cutOfPrice: string;
  minOrderPrice: string;
  pricePerUnit: string;
  unitWorth: string;
  name: string;
}

export interface StrategyAddEditModalProps {
  isOpen: any;
  setIsOpen: any;
  handleUpdateStrategy: any;
  isLoadingButton: any;
  isEdit: any;
  isDuplicate: any;
  setIsOpenStrategyEditModal: any;
  selectedStrategyNode: any;
}

/**
 * StrategyEditModal Component
 *
 * This component is responsible for rendering a modal dialog for adding, editing, or duplicating a strategy.
 * It includes fields for strategy name, delivery types (Dine In, Delivery, Takeout), and availability.
 * The user can select delivery types using switches and set availability using an availability selection component.
 * Additionally, the component provides options for canceling, saving, or creating the strategy.
 */
const StrategyEditModal: React.FunctionComponent<StrategyAddEditModalProps> = ({
  isOpen,
  setIsOpen,
  handleUpdateStrategy,
  isLoadingButton,
  isEdit,
  isDuplicate,
  selectedStrategyNode,
  setIsOpenStrategyEditModal,
}) => {
  const [isActive, setIsActive] = useState(false);
  const [isActiveInitial, setIsActiveInitial] = useState(false);

  const {
    register,
    handleSubmit,
    errors,
    reset,
    formState: { isDirty },
  } = useForm<FormData>({
    defaultValues: {
      cutOfPrice: "",
      minOrderPrice: "",
      pricePerUnit: "",
      unitWorth: "",
      name: "",
    },
  });

  useEffect(() => {
    if (selectedStrategyNode) {
      console.log("Wefsdfsdfsdf", selectedStrategyNode);
      setIsActive(
        selectedStrategyNode.allocate_type === "stamps" ? true : false,
      );
      setIsActiveInitial(
        selectedStrategyNode.allocate_type === "stamps" ? true : false,
      );
      reset({
        cutOfPrice: selectedStrategyNode.cutoff_price,
        minOrderPrice: selectedStrategyNode.min_order_price,
        pricePerUnit: selectedStrategyNode.price_per_unit,
        unitWorth: selectedStrategyNode.unit_worth,
        name: selectedStrategyNode.name,
      });
    }
  }, [selectedStrategyNode]);

  const handleSubmitData = handleSubmit(async (data: any) => {
    const formData: any = {};
    formData.cutoff_price = data.cutOfPrice;
    formData.min_order_price = data.minOrderPrice;
    formData.price_per_unit = isActive ? 1 : data.pricePerUnit;
    formData.allocate_type = isActive ? "stamps" : "points";
    formData.unit_worth = data.unitWorth;
    formData.name = data.name;
    formData.created = selectedStrategyNode.created;
    formData.strategyId = selectedStrategyNode.strategyId;
    formData.isActive = selectedStrategyNode.isActive;

    handleUpdateStrategy(formData);
  });

  const handleSelectPointsOrStamps = () => {
    setIsActive(!isActive);
  };

  return (
    <>
      <DialogCommonDefault
        open={isOpen}
        setOpen={setIsOpen}
        isNeedFixedHeight={false}
      >
        <DialogTitle>Edit Strategy</DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={12}>
              <Typography style={{ fontWeight: "bold" }}>
                Strategy Name
              </Typography>
              <Typography variant="caption">
                Provide a name for this strategy for easy identification in the
                future.
              </Typography>
              <div style={{ display: "flex" }}>
                <TextfieldDefaultNew
                  id="name"
                  name="name"
                  type="text"
                  inputRef={register({
                    required: "Name is required",
                    min: {
                      value: 0,
                      message: "Name cannot be negative",
                    },
                  })}
                />
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: "12px",
                  }}
                >
                  <FormControlLabel
                    style={{ marginLeft: "0px", marginRight: "0px" }}
                    value="dinein"
                    control={<SwitchCommon />}
                    label={
                      <Typography variant="caption" color="textSecondary">
                        Points
                      </Typography>
                    }
                    name="dinein"
                    checked={isActive}
                    labelPlacement="start"
                    onChange={handleSelectPointsOrStamps}
                  />
                  <Typography variant="caption" color="textSecondary">
                    Stamps
                  </Typography>
                </div>
              </div>
              {errors.name ? (
                <ValidationMessage message={errors.name.message} />
              ) : (
                ""
              )}
            </Grid>
            <Grid item xs={12} style={{ marginTop: "8px" }}>
              <Typography style={{ fontWeight: "bold" }}>
                {!isActive
                  ? "Price Cutoff for Points"
                  : "Cost of a Single Stamp"}
              </Typography>
              <Typography variant="caption">
                {!isActive
                  ? "Leave at 0.00 to allocate points for the entire order value. Otherwise, points will be allocated only up to the specified cutoff value per order."
                  : "Leave at 0.00 to allocate 1 stamp per order regardless of the order value. Otherwise, multiple stamps will be allocated based on the order value divided by this amount."}
              </Typography>
              <TextfieldDefaultNew
                id="cutOfPrice"
                name="cutOfPrice"
                type="number"
                inputRef={register({
                  required: "Cut Of Price is required",
                  min: {
                    value: 0,
                    message: "Cut Of Price cannot be negative",
                  },
                })}
              />
              {errors.cutOfPrice ? (
                <ValidationMessage message={errors.cutOfPrice.message} />
              ) : (
                ""
              )}
            </Grid>
            <Grid item xs={12} style={{ marginTop: "8px" }}>
              <Typography style={{ fontWeight: "bold" }}>
                Minimum Order Price
              </Typography>
              <Typography variant="caption">
                Specify a minimum threshold for allocating points or stamps.
                Leave at 0.00 if no minimum is required.
              </Typography>
              <TextfieldDefaultNew
                id="minOrderPrice"
                name="minOrderPrice"
                type="number"
                inputRef={register({
                  required: "Min Order Price is required",
                  min: {
                    value: 0,
                    message: "Min Order Price cannot be negative",
                  },
                })}
              />
              {errors.minOrderPrice ? (
                <ValidationMessage message={errors.minOrderPrice.message} />
              ) : (
                ""
              )}
            </Grid>
            {!isActive && (
              <Grid item xs={12} style={{ marginTop: "8px" }}>
                <Typography style={{ fontWeight: "bold" }}>
                  Points per £1 Spent
                </Typography>
                <Typography variant="caption">
                  Specify the number of points to be awarded for each £1 spent.
                  The total points will be calculated by multiplying the order
                  value by this number.
                </Typography>
                <TextfieldDefaultNew
                  id="pricePerUnit"
                  name="pricePerUnit"
                  type="number"
                  inputRef={register({
                    required: "Price Per Unit is required",
                    min: {
                      value: 0,
                      message: "Price Per Unit cannot be negative",
                    },
                  })}
                />
                {errors.pricePerUnit ? (
                  <ValidationMessage message={errors.pricePerUnit.message} />
                ) : (
                  ""
                )}
              </Grid>
            )}
            {/* <Grid item xs={12}>
              <Typography style={{ paddingLeft: "8px" }}>Unit Worth</Typography>
              <TextfieldDefaultNew
                id="unitWorth"
                name="unitWorth"
                type="number"
                inputRef={register({
                  required: "Unit Worth is required",
                  min: {
                    value: 0,
                    message: "Unit Worth cannot be negative",
                  },
                })}
              />
              {errors.unitWorth ? (
                <ValidationMessage message={errors.unitWorth.message} />
              ) : (
                ""
              )}
            </Grid> */}
          </Grid>
        </DialogContent>
        <DialogActions style={{ marginBottom: "8px" }}>
          <ButtonCommon
            variant="contained"
            style={{ fontSize: 11, width: "100px", marginRight: 8 }}
            color="yellow"
            onClick={() => setIsOpenStrategyEditModal(false)}
          >
            Cancel
          </ButtonCommon>
          <ButtonCommon
            variant="contained"
            style={{
              fontSize: 11,
              marginLeft: 4,
              width: "100px",
            }}
            disabled={!isDirty && isActive === isActiveInitial ? true : false}
            isLoadingPage={isLoadingButton}
            color={"orange"}
            onClick={handleSubmitData}
          >
            {"Save"}
          </ButtonCommon>
        </DialogActions>
      </DialogCommonDefault>
    </>
  );
};

export default StrategyEditModal;
