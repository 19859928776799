import React, { useEffect, useState } from "react";
import _ from "lodash";
import {
  Table,
  Header,
  HeaderRow,
  Body,
  Row,
  Cell,
  HeaderCell,
} from "@table-library/react-table-library/table";
import { useLocation } from "react-router";
import GetAppIcon from "@material-ui/icons/GetApp";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import SearchIcon from "@material-ui/icons/Search";
import {
  Button,
  createStyles,
  Grid,
  InputAdornment,
  makeStyles,
  Menu,
  MenuItem,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import "jspdf-autotable";
import { useTree } from "@table-library/react-table-library/tree";

import CardCommon from "../../../../components/card/CardCommon";
import MenuButtonCommon from "../../../../components/buttons/MenuButtonCommon";
import TextfieldCommon from "../../../../components/textField/TextfieldCommon";
import { handleDownloadPdf } from "./convertPdfExcel/convertDataToPdf";
import { handleExcel } from "./convertPdfExcel/convertDataToExcel";
import { CustomTheme } from "../../../../types/customTheme";
import { checkIncludedValue } from "../../../../utils/commonArrayMap";
import LocationDetailsCard from "../../../../components/common/LocationDetailsCard";

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    startTextStyle: {
      fontWeight: "bold",
      display: "flex",
      color: theme.palette.custom.blue.contrastText,
      justifyContent: "start",
      paddingLeft: "16px",
    },
    startNotNodeTextStyle: {
      fontWeight: "bold",
      display: "flex",
      color: theme.palette.text.primary,
      justifyContent: "start",
      paddingLeft: "16px",
    },
    startHeaderTextStyle: {
      fontWeight: "bold",
      fontSize: "12px",
      display: "flex",
      color: theme.palette.custom.orange.contrastText,
      justifyContent: "start",
      paddingLeft: "16px",
    },
    textStyleHeader: {
      display: "flex",
      justifyContent: "center",
      fontWeight: "bold",
      fontSize: "12px",
      color: theme.palette.custom.orange.contrastText,
    },
    textStyleHeaderEnd: {
      display: "flex",
      justifyContent: "end",
      fontWeight: "bold",
      fontSize: "12px",
      color: theme.palette.custom.orange.contrastText,
      paddingRight: "16px",
    },
    textStyle: {
      display: "flex",
      justifyContent: "center",
      fontSize: "12px",
    },
    textStyleFooter: {
      display: "flex",
      justifyContent: "center",
      fontWeight: "bold",
      fontSize: "12px",
      color: "white",
    },
    finalTotalRow: {
      cursor: "pointer",
      height: "40px",
      [`&:nth-of-type(n)`]: {
        color: theme.palette.custom.green.contrastText,
        backgroundColor: theme.palette.custom.green.main,
        fontWeight: "bold",
        "&:hover": {
          backgroundColor: theme.palette.custom.green.light,
        },
      },
    },
    headerStyle: {
      "&:last-of-type": {
        backgroundColor: theme.palette.background.entity_background,
        color: theme.palette.custom.orange.contrastText,
      },
    },
    mainRow: {
      cursor: "pointer",
      "&.css-16gtl2w": {
        display: "flex",
        justifyContent: "center",
      },
      "&:nth-of-type(n)": {
        color: theme.palette.custom.blue.contrastText,
        backgroundColor: theme.palette.custom.blue.main,
        "&:hover": {
          backgroundColor: theme.palette.custom.blue.light,
        },
      },
    },
    normalRow: {
      cursor: "pointer",
      "&.css-16gtl2w": {
        display: "flex",
        justifyContent: "center",
      },
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.background.paper,
        "&:hover": {
          backgroundColor: theme.palette.action.hover,
        },
      },
      "&:nth-of-type(even)": {
        backgroundColor: theme.palette.background.entity_background,
        "&:hover": {
          backgroundColor: theme.palette.action.hover,
        },
      },
    },
    baseCellRowEnd: {
      placeItems: "center",
      fontSize: "12px",
      "& .css-16gtl2w": {
        fontWeight: "bold",
      },
      height: "40px",
      [`&:nth-of-type(-n+7)`]: {
        minWidth: `${80 / 6}%`,
        width: `${80 / 6}%`,
        "& .css-16gtl2w": {
          display: "flex",
          justifyContent: "end",
          fontWeight: "bold",
        },
      },
      "&:nth-of-type(1)": {
        left: "0px",
        minWidth: "20%",
        width: "20%",
      },
      [theme.breakpoints.down("sm")]: {
        [`&:nth-of-type(-n+7)`]: {
          minWidth: `${20}%`,
          width: `${20}%`,
          "& .css-16gtl2w": {
            display: "flex",
            justifyContent: "end",
            fontWeight: "bold",
          },
        },
      },
      [theme.breakpoints.down("xs")]: {
        [`&:nth-of-type(-n+7)`]: {
          minWidth: `${32}%`,
          width: `${32}%`,
          "& .css-16gtl2w": {
            display: "flex",
            justifyContent: "end",
            fontWeight: "bold",
          },
        },
        "&:nth-of-type(1)": {
          left: "0px",
          minWidth: "36%",
          width: "36%",
        },
      },
    },
    baseCellRow: {
      placeItems: "center",
      fontSize: "12px",
      "& .css-16gtl2w": {
        fontWeight: "bold",
      },
      height: "40px",
      [`&:nth-of-type(n+2):nth-of-type(-n+6)`]: {
        minWidth: `${80 / 6}%`,
        width: `${80 / 6}%`,
        "& .css-16gtl2w": {
          display: "flex",
          justifyContent: "center",
          fontWeight: "bold",
        },
      },
      [`&:nth-of-type(7)`]: {
        minWidth: `${80 / 6}%`,
        width: `${80 / 6}%`,
        "& .css-16gtl2w": {
          display: "flex",
          justifyContent: "end",
          fontWeight: "bold",
          paddingRight: "12px",
        },
      },
      "&:nth-of-type(1)": {
        left: "0px",
        minWidth: "20%",
        width: "20%",
      },
      [theme.breakpoints.down("sm")]: {
        [`&:nth-of-type(n+2):nth-of-type(-n+6)`]: {
          minWidth: `${20}%`,
          width: `${20}%`,
          "& .css-16gtl2w": {
            display: "flex",
            justifyContent: "center",
            fontWeight: "bold",
          },
        },
        [`&:nth-of-type(7)`]: {
          minWidth: `${20}%`,
          width: `${20}%`,
          "& .css-16gtl2w": {
            display: "flex",
            justifyContent: "end",
            fontWeight: "bold",
            paddingRight: "12px",
          },
        },
      },
      [theme.breakpoints.down("xs")]: {
        [`&:nth-of-type(n+2):nth-of-type(-n+6)`]: {
          minWidth: `${32}%`,
          width: `${32}%`,
          "& .css-16gtl2w": {
            display: "flex",
            justifyContent: "center",
            fontWeight: "bold",
          },
        },
        [`&:nth-of-type(7)`]: {
          minWidth: `${32}%`,
          width: `${32}%`,
          "& .css-16gtl2w": {
            display: "flex",
            justifyContent: "end",
            fontWeight: "bold",
            paddingRight: "12px",
          },
        },
        "&:nth-of-type(1)": {
          left: "0px",
          minWidth: "36%",
          width: "36%",
        },
      },
    },
    baseCellRowChangeColor: {
      fontSize: "12px",
      "&.td:nth-of-type(n)": {
        color: theme.palette.custom["purple"].contrastText,
        backgroundColor: theme.palette.custom.purple.main,
      },
      placeItems: "center",
      "& .css-16gtl2w": {
        fontWeight: "bold",
      },
    },
  }),
);

export interface ItemsReportInfoNodeProps {
  filterDetails: any;
  locationSelectedList: any;
  handleOnClickText: any;
  itemsSalesByCategoryNode: any;
  setDepartmentList: any;
  nodes: any;
  setNodes: any;
}

/**
 * SaleItemReportNew Component
 *
 * This component renders a detailed report of items information, grouped by location and department.
 * It includes a location details card, search functionality, and options for exporting data as PDF or CSV.
 * The component dynamically generates tables for each group of items, displaying item details such as name, price, quantity, etc.
 * Users can interact with the items, and export the data in different formats using the provided menu options.
 * />
 */
const SaleItemReportNew: React.FunctionComponent<
  ItemsReportInfoNodeProps
> = ({
  filterDetails,
  locationSelectedList,
  handleOnClickText,
  itemsSalesByCategoryNode,
  setDepartmentList,
  nodes,
  setNodes,
}) => {
  const [pdfHeaders, setPdfHeaders] = useState<any>([]);
  const [headersList, setHeadersList] = useState<any>([]);
  const [excelHeaders, setExcelHeaders] = useState<any>([]);
  const [nodesInitial, setNodesInitial] = useState<any>({ nodes: [] });
  const [nodesPdfExcel, setNodesPdfExcel] = useState<any>([]);
  const [nodesPdfExcelInitial, setNodesPdfExcelInitial] = useState<any>([]);
  const [searchValue, setSearchValue] = useState("");
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const { search } = useLocation();
  const maxWidth = useMediaQuery("(max-width: 960px)");
  const maxWidthCustom = useMediaQuery("(max-width: 2600px)");
  const params = new URLSearchParams(search);
  const classes: any = useStyles();
  const open = Boolean(anchorEl);
  const maxWidthLocationChips = useMediaQuery("(max-width: 600px)");
  const theme: CustomTheme = useTheme();

  // useEffect hook to execute a function when the value of "startDate" parameter changes
  useEffect(() => {
    // Update the state "nodes" to an empty array when the value of "startDate" parameter changes
    // This effectively clears the existing data related to nodes when the start date changes
    setNodes({ nodes: [] }); // Setting nodes to an empty array
  }, [params.get("startDate")]); // Dependency array ensures this effect runs when "startDate" changes

  /**
   * Check for duplicate item names, unit prices, and VAT percents in the given array of nodes.
   * @param {Array} nodes - Array of objects representing nodes.
   * @returns {Object} - An object containing the duplicate status of item names, unit prices, and VAT percents.
   */
  const handleDuplicateItem = (nodes: any) => {
    // Extracting item names from the nodes array
    const itemNameList = nodes.map((item: any) => item.itemName);

    // Checking for duplicate item names
    const isDuplicateItemName =
      new Set(itemNameList).size !== itemNameList.length;

    // Extracting unit prices from the nodes array
    const valueArrUnitPrice = nodes.map((item: any) => item.unitPrice);

    // Checking for duplicate unit prices
    const isDuplicateUnitPrice =
      new Set(valueArrUnitPrice).size !== valueArrUnitPrice.length;

    // Extracting VAT percents from the nodes array
    const valueArrVatPercent = nodes.map((item: any) => item.vatPercent);

    // Checking for duplicate VAT percents
    const isDuplicateVatPercent =
      new Set(valueArrVatPercent).size !== valueArrVatPercent.length;

    // Returning an object containing the duplicate status of item names, unit prices, and VAT percents
    return {
      isDuplicateItemName,
      isDuplicateUnitPrice,
      isDuplicateVatPercent,
    };
  };

  /**
   * This useEffect hook is responsible for generating report nodes, PDF nodes,
   * custom footers, and department lists based on the itemsSalesByCategoryNode data.
   */
  useEffect(() => {
    // Clone the itemsSalesByCategoryNode to prevent mutation
    let cloneItemsSalesByCategoryNode: any = _.cloneDeep(
      itemsSalesByCategoryNode,
    );

    // Initialize arrays to store report nodes, PDF nodes, custom footer, and department list
    const itemsReportNode: any = [];
    const pdfNodeList: any = [];
    const customFooter: any = [];
    const departmentList: any = [];

    // Check if cloneItemsSalesByCategoryNode is not empty
    if (!_.isEmpty(cloneItemsSalesByCategoryNode)) {
      // Loop through each group in cloneItemsSalesByCategoryNode
      cloneItemsSalesByCategoryNode.forEach((group: any, index: any) => {
        group.department.forEach((department: any) => {
          // Store department details in departmentList if it's the first group
          if (index === 0) {
            departmentList.push({ id: department.id, name: department.name });
          }

          // Initialize variables to calculate totals and arrays to store nodes for the current table
          let itemsReportNodeCurrentTable: any = [];
          let pdfNodeListCurrentTable: any = [];
          let totalAmount = 0;
          let totalQty = 0;
          let totalDiscount = 0;
          let totalVatAmount = 0;

          // Iterate over products in the department
          Object.keys(department.product).forEach((key: any) => {
            // Check if there's only one product for the key
            const product = department.product[key];
            if (department.product[key].length === 1) {
              department.product[key].forEach((data: any) => {
                // Calculate VAT
                let vat =
                  data.vatPercent > 100
                    ? data.vatPercent / 1000
                    : data.vatPercent;

                // Push data to current table nodes
                itemsReportNodeCurrentTable.push({
                  id: key,
                  itemName: data.itemName,
                  unitPrice: parseFloat(data.unitPrice).toFixed(2),
                  amount: parseFloat(data.amount).toFixed(2),
                  qty: data.qty,
                  discount: parseFloat(data.discount).toFixed(2),
                  vatPercent: vat,
                  vatAmount: parseFloat(data.vatAmount).toFixed(2),
                });

                // Push data to PDF nodes
                pdfNodeListCurrentTable.push({
                  id: key,
                  itemName: data.itemName,
                  unitPrice: parseFloat(data.unitPrice).toFixed(2),
                  amount: parseFloat(data.amount).toFixed(2),
                  qty: data.qty,
                  discount: parseFloat(data.discount).toFixed(2),
                  vatPercent: vat,
                  vatAmount: parseFloat(data.vatAmount).toFixed(2),
                  nodes: "",
                });

                // Update totals
                totalAmount = totalAmount + data.amount;
                totalQty = totalQty + data.qty;
                totalDiscount = totalDiscount + data.discount;
                totalVatAmount = totalVatAmount + data.vatAmount;
              });
            } else {
              // If there are multiple products for the key
              let itemName = "";
              let joinedUnitPrice = "";
              let unitPrice = "";
              let amount = 0;
              let qty = 0;
              let discount = 0;
              let joinedVatPercent = "";
              let vatPercent = "";
              let vatAmount = 0;
              let nodes: any = [];
              let pdfNodes: any = [];

              department.product[key].map((data: any) => {
                // Calculate VAT
                let vat =
                  data.vatPercent > 100
                    ? data.vatPercent / 1000
                    : data.vatPercent;

                // Update itemName if it's empty
                if (!_.isEmpty(data.itemName) && _.isEmpty(itemName)) {
                  itemName = data.itemName;
                }

                // Update joinedUnitPrice
                joinedUnitPrice = Array.from(
                  new Set(
                    [parseFloat(data.unitPrice).toFixed(2), unitPrice]
                      .join(",")
                      .split(","),
                  ),
                ).join(",");
                unitPrice = joinedUnitPrice;

                // Update joinedVatPercent
                joinedVatPercent = Array.from(
                  new Set([vat, vatPercent].join(",").split(",")),
                ).join(",");
                vatPercent = joinedVatPercent;

                // Update amounts and quantities
                amount = amount + data.amount;
                qty = qty + data.qty;
                discount = discount + data.discount;
                vatAmount = vatAmount + data.vatAmount;

                // Push data to nodes
                nodes.push({
                  id: key,
                  itemName: data.itemName,
                  unitPrice: parseFloat(data.unitPrice).toFixed(2),
                  amount: parseFloat(data.amount).toFixed(2),
                  qty: data.qty,
                  discount: parseFloat(data.discount).toFixed(2),
                  vatPercent: vat,
                  vatAmount: parseFloat(data.vatAmount).toFixed(2),
                });

                // Push data to PDF nodes
                pdfNodes.push({
                  id: key,
                  itemName: data.itemName,
                  unitPrice: parseFloat(data.unitPrice).toFixed(2),
                  amount: parseFloat(data.amount).toFixed(2),
                  qty: data.qty,
                  discount: parseFloat(data.discount).toFixed(2),
                  vatPercent: vat,
                  vatAmount: parseFloat(data.vatAmount).toFixed(2),
                  nodes: "sub",
                });

                // Update totals
                totalAmount = totalAmount + data.amount;
                totalQty = totalQty + data.qty;
                totalDiscount = totalDiscount + data.discount;
                totalVatAmount = totalVatAmount + data.vatAmount;
              });

              // Handle duplicate items
              const {
                isDuplicateItemName,
                isDuplicateUnitPrice,
                isDuplicateVatPercent,
              } = handleDuplicateItem(nodes);

              // Add duplicate flags to nodes
              nodes.map((data: any) => {
                data["isDuplicateItemName"] = isDuplicateItemName;
                data["isDuplicateUnitPrice"] = isDuplicateUnitPrice;
                data["isDuplicateVatPercent"] = isDuplicateVatPercent;
              });
              pdfNodes.map((data: any) => {
                data["isDuplicateItemName"] = isDuplicateItemName;
                data["isDuplicateUnitPrice"] = isDuplicateUnitPrice;
                data["isDuplicateVatPercent"] = isDuplicateVatPercent;
              });

              // Push data to itemsReportNodeCurrentTable
              itemsReportNodeCurrentTable.push({
                id: key,
                itemName: itemName,
                unitPrice: unitPrice.slice(0, -1).replaceAll(",", ", "),
                amount: parseFloat(amount.toString()).toFixed(2),
                qty: qty,
                discount: parseFloat(discount.toString()).toFixed(2),
                vatPercent: vatPercent.slice(0, -1).replaceAll(",", ", "),
                vatAmount: parseFloat(vatAmount.toString()).toFixed(2),
                nodes: nodes,
              });

              // Push data to pdfNodeListCurrentTable
              pdfNodeListCurrentTable.push({
                id: key,
                itemName: itemName,
                unitPrice: unitPrice.slice(0, -1).replaceAll(",", ", "),
                amount: parseFloat(amount.toString()).toFixed(2),
                qty: qty,
                discount: parseFloat(discount.toString()).toFixed(2),
                vatPercent: vatPercent.slice(0, -1).replaceAll(",", ", "),
                vatAmount: parseFloat(vatAmount.toString()).toFixed(2),
                nodes: "main",
              });
              pdfNodes.map((data: any) => {
                pdfNodeListCurrentTable.push(data);
              });
            }
          });

          // Create footer for the current table
          const footerCurrentTable = {
            id: "",
            itemName: "Total",
            unitPrice: "",
            amount: parseFloat(totalAmount.toString()).toFixed(2),
            qty: totalQty,
            discount: parseFloat(totalDiscount.toString()).toFixed(2),
            vatPercent: "",
            vatAmount: parseFloat(totalVatAmount.toString()).toFixed(2),
            nodes: "",
          };

          // Push total to itemsReportNodeCurrentTable
          itemsReportNodeCurrentTable.push({
            id: "",
            itemName: "Total",
            unitPrice: "",
            amount: parseFloat(totalAmount.toString()).toFixed(2),
            qty: totalQty,
            discount: parseFloat(totalDiscount.toString()).toFixed(2),
            vatPercent: "",
            vatAmount: parseFloat(totalVatAmount.toString()).toFixed(2),
            nodes: "",
          });

          // Push total to pdfNodeListCurrentTable
          pdfNodeListCurrentTable.push({
            id: "",
            itemName: "Total",
            unitPrice: "",
            amount: parseFloat(totalAmount.toString()).toFixed(2),
            qty: totalQty,
            discount: parseFloat(totalDiscount.toString()).toFixed(2),
            vatPercent: "",
            vatAmount: parseFloat(totalVatAmount.toString()).toFixed(2),
            nodes: "",
          });

          // Push current table data to pdfNodeList and itemsReportNode
          pdfNodeList.push({
            groupId: group.id,
            departmentId: department.id,
            groupName: group.name,
            departmentName: department.name,
            items: pdfNodeListCurrentTable,
          });
          itemsReportNode.push({
            groupId: group.id,
            departmentId: department.id,
            groupName: group.name,
            departmentName: department.name,
            items: itemsReportNodeCurrentTable,
          });

          // Push current table footer to customFooter
          customFooter.push(footerCurrentTable);
        });
      });
      // setFooter(customFooter);

      // setFooterInitial(customFooter);

      // Set headers for the tables
      setHeadersList([
        "Item Name",
        "Unit Price",
        "Amount",
        "Quantity",
        "Discount",
        "Vat Percent",
        "Vat Amount",
      ]);

      // Set PDF headers
      setPdfHeaders([
        { title: "Item Name", field: "itemName" },
        { title: "Unit Price", field: "unitPrice" },
        { title: "Amount", field: "amount" },
        { title: "Quantity", field: "qty" },
        { title: "Discount", field: "discount" },
        { title: "Vat Percent", field: "vatPercent" },
        { title: "Vat Amount", field: "vatAmount" },
      ]);

      // Set Excel headers
      setExcelHeaders([
        { header: "Item Name", key: "itemName", width: 52, height: 68 },
        { header: "Unit Price", key: "unitPrice", width: 32, height: 68 },
        { header: "Amount", key: "amount", width: 32, height: 68 },
        { header: "Quantity", key: "qty", width: 32, height: 68 },
        { header: "Discount", key: "discount", width: 32, height: 68 },
        { header: "Vat Percent", key: "vatPercent", width: 32, height: 68 },
        { header: "Vat Amount", key: "vatAmount", width: 32, height: 68 },
      ]);

      // Set nodes for PDF and Excel
      setNodesPdfExcel(pdfNodeList);
      setNodesPdfExcelInitial(pdfNodeList);
      setNodes({ nodes: itemsReportNode });
      setNodesInitial({ nodes: itemsReportNode });
      setDepartmentList(departmentList);
    }
  }, [itemsSalesByCategoryNode]);

  /**
   * Define a function named handleClick which takes a MouseEvent as input
   */
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    // When the button is clicked, set the anchor element to the current target of the event
    setAnchorEl(event.currentTarget);
  };

  // Define a function named handleClose
  const handleClose = () => {
    // Set the anchor element to null, effectively closing any anchored element
    setAnchorEl(null);
  };

  /**
   * Define a variable named tree that utilizes the useTree hook
   */
  const tree = useTree(
    // Pass the nodes data to the useTree hook
    nodes,
    // Pass an empty object as the second parameter (options)
    {},
    {
      // Pass configuration options for the tree icons
      treeIcon: {
        // Set margin for the tree icons
        margin: "4px",
        // Set the default icon for nodes that can expand/collapse downwards
        iconDefault: <ArrowDropDownIcon />,
        // Set the icon for nodes that can expand/collapse to the right
        iconRight: <ArrowDropDownIcon />,
        // Set the icon for expanded nodes
        iconDown: <ArrowDropUpIcon />,
      },
    },
  );

  /**
   * Function to convert input data structure to the desired output format.
   * @param data The input data to be transformed.
   * @returns The transformed data in the desired output format.
   */
  const transformDataStructure = (data: any) => {
    // Map over the input data array
    return data.map((entry: any) => ({
      // Spread the current entry to maintain its properties
      ...entry,
      // Modify the 'items' array of the current entry
      items: entry.items.reduce((acc: any, item: any) => {
        // Create a main item with a 'nodes' property indicating if it's a main item or not
        const mainItem = {
          ...item,
          nodes: item?.nodes?.length > 0 ? "main" : "",
        };
        // Push the main item to the accumulator array
        acc.push(mainItem);
        // If there are nodes present in the item
        if (item.nodes && item.nodes.length > 0) {
          // Iterate over each node
          item.nodes.forEach((subItem: any) => {
            // Push each node as a sub-item with 'nodes' property set to "sub"
            acc.push({ ...subItem, nodes: "sub" });
          });
        }
        // Return the accumulator array
        return acc;
      }, []),
    }));
  };

  /**
   * Function to handle search input change and update nodes and nodesPdfExcel states accordingly.
   * @param e The event object containing the target value.
   */
  const handleChangeSearch = (e: any) => {
    // Set the search value state
    setSearchValue(e.target.value);

    // Reset nodes state if search value is empty
    if (e.target.value === "") {
      setNodes(nodesInitial);
      setNodesPdfExcel(nodesPdfExcelInitial);
    } else {
      // Filter nodes based on search criteria
      const filteredNodes = nodesInitial.nodes
        .map((department: any) => ({
          ...department,
          items: department.items.filter((item: any) => {
            if (item.itemName !== "Total") {
              // Check if any item property includes the search value
              if (
                checkIncludedValue(item.itemName, e.target.value) ||
                checkIncludedValue(item.unitPrice, e.target.value) ||
                checkIncludedValue(item.amount, e.target.value) ||
                checkIncludedValue(item.qty.toString(), e.target.value) ||
                checkIncludedValue(item.discount, e.target.value) ||
                checkIncludedValue(
                  item.vatPercent.toString(),
                  e.target.value,
                ) ||
                checkIncludedValue(item.vatAmount, e.target.value)
              ) {
                return item;
              }
            }
          }),
        }))
        .filter((department: any) => department.items.length > 0);

      // Calculate totals for each department
      filteredNodes.forEach((department: any) => {
        let totalAmount = 0;
        let totalQty = 0;
        let totalDiscount = 0;
        let totalVatAmount = 0;
        department.items.forEach((item: any) => {
          totalAmount += parseFloat(item.amount);
          totalQty += item.qty;
          totalDiscount += parseFloat(item.discount);
          totalVatAmount += parseFloat(item.vatAmount);
        });

        // Add total item to each department
        department.items.push({
          id: "",
          itemName: "Total",
          unitPrice: "",
          amount: parseFloat(totalAmount.toString()).toFixed(2),
          qty: totalQty,
          discount: parseFloat(totalDiscount.toString()).toFixed(2),
          vatPercent: "",
          vatAmount: parseFloat(totalVatAmount.toString()).toFixed(2),
          nodes: "",
        });
      });

      // Transform filtered nodes for Excel
      const excelData = transformDataStructure(filteredNodes);

      // Set the nodes and nodesPdfExcel states
      setNodes({ nodes: filteredNodes });
      setNodesPdfExcel(excelData);
    }
  };

  return (
    <>
      <>
        <Grid container spacing={2} style={{ marginTop: "8px" }}>
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
            }}
          >
            <div>
              <LocationDetailsCard
                locationSelectedList={locationSelectedList}
                handleOnClickText={handleOnClickText}
              />
            </div>
          </Grid>
        </Grid>
        <div
          style={
            maxWidthLocationChips
              ? { display: "block", marginTop: "8px", marginBottom: "20px" }
              : {
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "20px",
                }
          }
        >
          <TextfieldCommon
            id="search"
            name="search"
            label="Search"
            type="text"
            style={
              maxWidthLocationChips ? { width: "100%" } : { width: "240px" }
            }
            value={searchValue}
            onChange={handleChangeSearch}
            inputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <div
            style={
              maxWidthLocationChips
                ? {
                    marginBottom: "12px",
                    marginTop: "8px",
                  }
                : {
                    marginTop: "8px",
                  }
            }
          >
            <MenuButtonCommon
              id="basic-button"
              variant="contained"
              style={{
                fontSize: 11,
                height: "38px",
                paddingLeft: "16px",
                paddingRight: "16px",
              }}
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
              disabled={_.isEmpty(nodes.nodes)}
            >
              <GetAppIcon />
              <Typography
                variant="body1"
                style={{ paddingLeft: "16px", color: "white" }}
              >
                Export
              </Typography>
            </MenuButtonCommon>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
              PaperProps={{
                elevation: 0,
                style: {
                  borderRadius: "10px",
                  border: `1px solid ${theme.palette.background.entity_border}`,
                },
              }}
              style={{ top: "56px" }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <MenuItem
                onClick={() => {
                  handleDownloadPdf(filterDetails, pdfHeaders, nodesPdfExcel);
                  handleClose();
                }}
              >
                Export as PDF
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleExcel(excelHeaders, nodesPdfExcel, filterDetails);
                  handleClose();
                }}
              >
                Export as CSV
              </MenuItem>
            </Menu>
          </div>
        </div>
      </>
      {nodes.nodes.length > 0 &&
        nodes.nodes.map((data: any, index: number) => {
          return (
            <>
              {nodes.nodes[index - 1]?.groupId !== data.groupId && (
                <Typography
                  variant="h4"
                  align="left"
                  style={{ marginTop: index === 0 ? "0px" : "20px" }}
                >
                  {data.groupName}
                </Typography>
              )}
              <Typography
                variant="h5"
                align="left"
                id={data.departmentName}
                style={{
                  marginTop:
                    nodes.nodes[index - 1]?.groupId !== data.groupId
                      ? "0px"
                      : "20px",
                  marginBottom: "20px",
                }}
              >
                {data.departmentName}
              </Typography>
              <CardCommon>
                <Table
                  data={{ nodes: data.items }}
                  tree={tree}
                  key={index}
                  layout={{
                    custom: maxWidthCustom,
                    horizontalScroll: maxWidth,
                  }}
                  style={{ zIndex: 0 }}
                >
                  {(tableList) => (
                    <>
                      <Header style={{ zIndex: 0 }}>
                        <HeaderRow className={classes.headerStyle}>
                          {headersList.map((data: any, index: any) => (
                            <HeaderCell
                              sortKey={data}
                              pinLeft={index === 0 ? true : false}
                              className={classes.baseCellRow}
                            >
                              <Typography
                                variant="caption"
                                className={
                                  index === 0
                                    ? classes.startHeaderTextStyle
                                    : index === 6
                                    ? classes.textStyleHeaderEnd
                                    : classes.textStyleHeader
                                }
                              >
                                {data}
                              </Typography>
                            </HeaderCell>
                          ))}
                        </HeaderRow>
                      </Header>

                      <Body className={classes.bodyStyle}>
                        {tableList.map((item, bodyIndex) => (
                          <>
                            {bodyIndex + 1 !== tableList.length && (
                              <Row
                                key={item.id}
                                item={item}
                                className={
                                  item.nodes
                                    ? classes.mainRow
                                    : classes.normalRow
                                }
                              >
                                <Cell
                                  pinLeft={true}
                                  className={
                                    item.isDuplicateItemName === false
                                      ? classes.baseCellRowChangeColor
                                      : classes.baseCellRow
                                  }
                                >
                                  <Button
                                    className={
                                      item.nodes ||
                                      item.isDuplicateItemName === false
                                        ? classes.startTextStyle
                                        : classes.startNotNodeTextStyle
                                    }
                                    style={{ textTransform: "none" }}
                                    onClick={() =>
                                      window.open(
                                        `presto-sales/items/${item.id}`,
                                        "_blank",
                                      )
                                    }
                                  >
                                    <Typography
                                      variant="caption"
                                      align="left"
                                      style={
                                        item.nodes ||
                                        item.isDuplicateItemName === false
                                          ? {
                                              color: "white",
                                              fontWeight: "bold",
                                              textTransform: "none",
                                              fontSize: "12px",
                                            }
                                          : {
                                              fontWeight: "bold",
                                              textTransform: "none",
                                              fontSize: "12px",
                                            }
                                      }
                                    >
                                      {item.itemName}
                                    </Typography>
                                  </Button>
                                </Cell>
                                <Cell
                                  className={
                                    item.isDuplicateUnitPrice === false
                                      ? classes.baseCellRowChangeColor
                                      : classes.baseCellRow
                                  }
                                >
                                  <Typography
                                    variant="caption"
                                    className={classes.textStyle}
                                    style={
                                      item.nodes || item.isDuplicateItemName
                                        ? { color: "white" }
                                        : { marginTop: "0px" }
                                    }
                                  >
                                    {item.unitPrice}
                                  </Typography>
                                </Cell>
                                <Cell className={classes.baseCellRow}>
                                  <Typography
                                    variant="caption"
                                    className={classes.textStyle}
                                    style={
                                      item.nodes ||
                                      item.isDuplicateItemName === false
                                        ? { color: "white" }
                                        : { marginTop: "0px" }
                                    }
                                  >
                                    {item.amount}
                                  </Typography>
                                </Cell>
                                <Cell className={classes.baseCellRow}>
                                  <Typography
                                    variant="caption"
                                    className={classes.textStyle}
                                    style={
                                      item.nodes ||
                                      item.isDuplicateItemName === false
                                        ? { color: "white" }
                                        : { marginTop: "0px" }
                                    }
                                  >
                                    {item.qty}
                                  </Typography>
                                </Cell>

                                <Cell className={classes.baseCellRow}>
                                  <Typography
                                    variant="caption"
                                    className={classes.textStyle}
                                    style={
                                      item.nodes ||
                                      item.isDuplicateItemName === false
                                        ? { color: "white" }
                                        : { marginTop: "0px" }
                                    }
                                  >
                                    {item.discount}
                                  </Typography>
                                </Cell>
                                <Cell className={classes.baseCellRow}>
                                  <Typography
                                    variant="caption"
                                    className={
                                      item.nodes ||
                                      item.isDuplicateVatPercent === false
                                        ? classes.baseCellRowChangeColor
                                        : classes.baseCellRow
                                    }
                                    style={
                                      item.nodes
                                        ? { color: "white" }
                                        : { marginTop: "0px" }
                                    }
                                  >
                                    {item.vatPercent}
                                  </Typography>
                                </Cell>
                                <Cell className={classes.baseCellRowEnd}>
                                  <Typography
                                    variant="caption"
                                    className={classes.textStyle}
                                    style={
                                      item.nodes ||
                                      item.isDuplicateItemName === false
                                        ? {
                                            color: "white",
                                            display: "flex",
                                            justifyContent: "end",
                                            paddingRight: "16px",
                                          }
                                        : {
                                            display: "flex",
                                            justifyContent: "end",
                                            paddingRight: "16px",
                                          }
                                    }
                                  >
                                    {item.vatAmount}
                                  </Typography>
                                </Cell>
                              </Row>
                            )}
                            {bodyIndex + 1 === tableList.length && (
                              <>
                                <Row
                                  className={classes.finalTotalRow}
                                  item={item}
                                >
                                  <Cell
                                    pinLeft={true}
                                    className={classes.baseCellRow}
                                  >
                                    <Typography
                                      variant="caption"
                                      style={{
                                        fontWeight: "bold",
                                        textTransform: "none",
                                        display: "flex",
                                        justifyContent: "start",
                                        paddingLeft: "16px",
                                        fontSize: "12px",
                                        color: "white",
                                      }}
                                    >
                                      {item.itemName}
                                    </Typography>
                                  </Cell>
                                  <Cell className={classes.baseCellRow}>
                                    <Typography
                                      variant="caption"
                                      className={classes.textStyleFooter}
                                    >
                                      {item.unitPrice}
                                    </Typography>
                                  </Cell>
                                  <Cell className={classes.baseCellRow}>
                                    <Typography
                                      variant="caption"
                                      className={classes.textStyleFooter}
                                    >
                                      {item.amount}
                                    </Typography>
                                  </Cell>
                                  <Cell className={classes.baseCellRow}>
                                    <Typography
                                      variant="caption"
                                      className={classes.textStyleFooter}
                                    >
                                      {item.qty}
                                    </Typography>
                                  </Cell>

                                  <Cell className={classes.baseCellRow}>
                                    <Typography
                                      variant="caption"
                                      className={classes.textStyleFooter}
                                    >
                                      {item.discount}
                                    </Typography>
                                  </Cell>
                                  <Cell className={classes.baseCellRow}>
                                    <Typography
                                      variant="caption"
                                      className={classes.baseCellRow}
                                    >
                                      {item.vatPercent}
                                    </Typography>
                                  </Cell>
                                  <Cell className={classes.baseCellRowEnd}>
                                    <Typography
                                      variant="caption"
                                      className={classes.textStyleFooter}
                                      style={{
                                        display: "flex",
                                        justifyContent: "end",
                                        paddingRight: "16px",
                                      }}
                                    >
                                      {item.vatAmount}
                                    </Typography>
                                  </Cell>
                                </Row>
                              </>
                            )}
                          </>
                        ))}
                      </Body>
                    </>
                  )}
                </Table>
              </CardCommon>
            </>
          );
        })}
    </>
  );
};

export default SaleItemReportNew;
